import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import Cookie from 'js-cookie';
import * as React from 'react';
// import { withClientState } from 'apollo-link-state';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import * as ReduxLogic from 'redux-logic';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import configureStore, { logicMiddleware } from './store/configureStore';
// import { typeDefs, resolvers, defaults } from './resolvers';

// if (process.env.NODE_ENV !== 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update')
//   whyDidYouUpdate(React)
// }

const store = configureStore();

export const LogicContext =
  React.createContext<ReduxLogic.LogicMiddleware>(logicMiddleware);

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => console.log(message));
  }
});

// const stateLink = withClientState({
//   typeDefs,
//   defaults,
//   resolvers,
// });
// const backendURI = process.env.NODE_ENV !== 'production' ? 'http://localhost:3001/graphql' : '/graphql';

// const backendWsURI = process.env.NODE_ENV !== 'production' ? 'ws://localhost:3001/graphql' : 'wss://plan.frosch.gift/graphql';
// const backendWsURI = 'wss://plan.frosch.gift/graphql';
// Enable also local production tests
let localHostAdress = '';
const { hostname } = window.location;
if (hostname === 'localhost') {
  localHostAdress = 'localhost';
} else if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(hostname)) {
  localHostAdress = hostname;
} else if (hostname === 'plan-ui') {
  localHostAdress = 'plan-server';
}
if (localHostAdress) {
  console.log('Recognized hostname', localHostAdress);
  localHostAdress = `${localHostAdress}:3001`;
}

const uri = localHostAdress ? `http://${localHostAdress}/graphql` : '/graphql';
const httpLink = new HttpLink({ uri });

const backendWsURI = localHostAdress
  ? `ws://${localHostAdress}/graphql`
  : 'wss://plan.frosch.gift/graphql';
const wsLink = new WebSocketLink({
  uri: backendWsURI,
  options: {
    reconnect: true,
    connectionParams: () => {
      // const token = localStorage.getItem('auth_token');
      const token = Cookie.get('auth_token');
      const offerwebviewtoken = Cookie.get('offerwebview_token');
      if (token) {
        return { token, offerwebviewtoken };
      }
      return {};
    },
  },
});

console.log(`Using backend uri ${uri} and ${backendWsURI} for subscriptions`);

// const httpLink = new HttpLink({ uri: '/graphql' });
const authLink = new ApolloLink((operation, forward) => {
  if (!forward) {
    throw new Error('Forward is not a NextLink');
  }
  // Retrieve the authorization token from local storage.
  // const token = localStorage.getItem('auth_token');
  // User authorization
  const token = Cookie.get('auth_token');

  // Customer offer webview authorization
  const offerWebviewToken = Cookie.get('offerwebview_token');
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      offerwebviewauthorization: offerWebviewToken
        ? `Bearer ${offerWebviewToken}`
        : '',
    },
  });
  // Call the next link in the middleware chain.
  return forward(operation);
});

// Use websockets when the operations contain subscriptions
// and http otherwise
const connectionLink = ApolloLink.split(
  ({ query: { definitions } }) =>
    definitions.some(
      ({ kind, operation }: any) =>
        kind === 'OperationDefinition' && operation === 'subscription'
    ),
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, /* stateLink,*/ authLink, connectionLink]),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <IntlProvider locale="en">
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <Provider store={store}>
          <LogicContext.Provider value={logicMiddleware}>
            <App store={store} client={client} />
          </LogicContext.Provider>
        </Provider>
      </ApolloHooksProvider>
    </ApolloProvider>
  </IntlProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
